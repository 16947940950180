import React from "react"
import "./quiz.css"
import { Link } from "gatsby"
import $ from "jquery"
import quizData from "../../data/quiz/pharmacist"


export default class Quiz extends React.Component {
  state = {
   cookieID: "",
   sessionID: "",
   quizData: quizData,
   responses: "",
   valid: "",
   correct: 0,
   totalQuestions: quizData.length,
   submitted: false
  }

  componentDidMount(){
    // create sessionID which terminates on browser close
    let sessionID = Math.random().toString(36).substring(7);
    this.setState({
        sessionID: sessionID
    })
    // check for cookie 
    if (this.getCookie("cookieID") === ""){
    // if no cookie
    // Generate random string
    let cookieID = Math.random().toString(36).substring(7);
    // create cookie
    var CookieDate = new Date;
    CookieDate.setFullYear(CookieDate.getFullYear() +1);
    document.cookie = 'cookieID='+ cookieID +'; expires=' + CookieDate.toGMTString() + ';';
    document.cookie = 'sessionID='+ sessionID +';';
    // set cookie to state
    this.setState({
        cookieID: cookieID
    })
    }
    else{
        this.setState({
            cookieID : this.getCookie("cookieID")
        })
    }
    
    //set responses to null
    let responses = this.state.quizData.map((question) => ",null" ) 
    this.setState({
        responses:  responses.join("")
    })

    //if responses are in url, set them. 
    let query = window.location.href.split("?")[1]
    if(query){
    if (query[0]==="q"){
    let question = parseInt(query.split("&")[0].split("=")[1]) - 1
    let answer = query.split("&")[1].split("=")[1]
    let quizData = this.state.quizData
    quizData[question].response = answer 

    let questionBeforeImage = ".question" + question;
    document.querySelector(questionBeforeImage).classList.add("answered");

    document.getElementById(question+answer).setAttribute("checked","")
    
    responses = quizData.map((question) => { 
        if(question.response != ""){
        return(","+question.response)
        }
        else{
         return(",null")
        }
        }
        )
        responses = responses.join("")
        this.setState({
            quizData: quizData,
            responses: responses
        })
        
    }
    }

    const refBlock = document.querySelector('.quiz-ref');
    refBlock.classList.add('unsubmit');

  }

  setResponse = (event) => {
    let questionNumber = parseInt(event.target.parentElement.parentElement.dataset.number)
    let questionAnswer = event.target.dataset.response
    let quizData = this.state.quizData
    quizData[questionNumber].response = questionAnswer

    let questionBeforeImage = ".question" + questionNumber;
    document.querySelector(questionBeforeImage).classList.add("answered");
    
    var responses = quizData.map((question) => { 
    if(question.response != ""){
    return(","+question.response)
    }
    else{
     return(",null")
    }
    }
    )
    responses = responses.join("")
    this.setState({
        quizData: quizData,
        responses: responses
    })
    
    this.validateButton()
     
    
   }

   validateButton = () =>{
       if (this.state.responses.includes("null")){
           this.setState({
               valid: ""
           })
       }
       else{
           this.setState({
               valid: "valid"
           })
       }
   }

getCookie = (cname) =>{
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for(var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

submitQuiz = (event) => {
    document.querySelectorAll('.answered').forEach(question => question.classList.add('submitted'))
    if (!this.state.submitted && this.state.valid == "valid"){
        window.scrollTo(0,0)
        let completedQuiz = this.state.quizData
        let correct = 0
        for(let i = 0 ; i< completedQuiz.length; i++){
         if (completedQuiz[i].answer.toString() == completedQuiz[i].response){
             correct += 1
         }
        }

        this.setState({
            completed: "completed",
            correct: correct,
            submitted: true,
        })


    let dismissableElements = document.getElementsByClassName("dismissable")
        for (let i = 0 ; i < dismissableElements; i++){
            dismissableElements[i].setAttribute("disabled", "")
        }

        const refBlock = document.querySelector('.quiz-ref');
    refBlock.classList.remove('unsubmit');

    }

}






  render() {
    let quizRender = 
        quizData.map((question,i) =>
        <div className={"question-container question" + i}> 
            <div className="quiz-text">{question.text}</div>
                <div data-number={i} className="choice-wrapper">
                <label value="true" className="container dismissable" >
                    <input className="dismissable" type="radio" id={i+"true"} name={i} onClick={this.setResponse} data-response="true"  />
                    <span class="radio">True</span>
                    </label>

                <label value="false" className="container dismissable" >
                    <input className="dismissable" type="radio" id={i+"false"} name={i} onClick={this.setResponse} data-response="false"  />
                    <span class="radio">False</span>
                    </label>
            </div>
           
            <div className={"quiz-text description " + this.state.completed}>
                
                <div className={"answer-check " + this.state.completed}>
                    {question.answer.toString() == quizData[i].response ? "Correct!" : "Incorrect!"}
                </div>
                <br/>
                <div dangerouslySetInnerHTML={{__html: question.description}}/>
                </div>
            {i + 1 === quizData.length ? null : <hr className="quiz-horizontal-line "></hr>    }

         </div>
        )

    return (
        <div className="quiz-wrapper">
            <div className={"quiz-body-copy preface " + this.state.completed}>
                This quiz only takes a few minutes to complete, and may add to your knowledge of heart health.
                The questions explore the differences between fish oil dietary supplements, generic DHA-containing omega-3s, and FDA-approved VASCEPA<sup class="sup-8">&reg;</sup> (icosapent ethyl).
                Please answer all 4 questions before clicking submit. 
                You may change your answers at any time until you submit the full quiz.
                </div>

            <div>
                {quizRender}
            </div>        
      
        <button onClick={this.submitQuiz} className={this.state.completed + " quizButton "+ this.state.valid} data-tracking={this.state.cookieID + ","+this.state.sessionID+this.state.responses} other="attributes">Submit</button>
        
        
        <div className={"results " + this.state.completed}>
        You answered {Math.round(this.state.correct/this.state.totalQuestions * 100)}% of the questions correctly.
        </div>

        </div>
    )
  }
}
