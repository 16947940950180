let quizData = [
    {
        text: "Certain fish oil dietary supplements and generic omega-3s contain docosahexaenoic acid (DHA), which may raise a patient\'s \"bad\" cholesterol (LDL-C).",
        answer: true,
        description:"Many common fish oil dietary supplements and generic omega-3s contain DHA, which has the potential to raise “bad” cholesterol (LDL-C) levels and may undo your efforts to manage patients’ CV health.<sup>1-3</sup> VASCEPA is icosapent ethyl (IPE), a single purified ingredient that is FDA approved and proven to reduce CV risk in adults on maximally tolerated statins with TG ≥150 mg/dL and established CVD or diabetes and ≥2 CVD risk factors.<sup>4</sup>",
        response: "",
    },
    {
        text: "Fish oil dietary supplements are FDA approved to treat medical conditions including the management of CV risk.",
        answer: false,
        description:"Fish oil dietary supplements are regulated by the FDA as food, not drugs. They are not approved to diagnose, treat, cure, or prevent any disease.<sup>5</sup> VASCEPA is different. It is icosapent ethyl (IPE)—FDA approved to significantly reduce CV risk in adults on maximally tolerated statins with TG ≥150 mg/dL and established CVD or diabetes and ≥2 CVD risk factors.<sup>4</sup>",
        response: ""
    },
    {
        text: "The composition and content of fish oil dietary supplements are consistent and accurately described on their labels. ",
        answer: false,
        description:"Fish oil dietary supplements do not have to meet strict FDA standards for prescription drug approval, so they frequently vary in actual DHA and EPA content and composition. Plus, they can contain up to 36% saturated fats and oxidized lipids.<sup>1,6,7</sup> VASCEPA is icosapent ethyl (IPE), which has undergone a proprietary purification process, leaving only a single purified ingredient* proven to reduce CV risk in adults on maximally tolerated statins with TG ≥150 mg/dL and established CVD or diabetes and ≥2 CVD risk factors.<sup>4</sup> As you know, products that contain DHA may raise “bad” cholesterol (LDL-C), which is why it is important to know the difference between fish oil dietary supplements and VASCEPA when it comes to supporting CV health.<sup>2,3,8</sup>",
        response: ""
    },
    // {
    //     text: "Fish oil dietary supplements are FDA approved to treat medical conditions including the management of CV risk.",
    //     answer: true,
    //     description:"Lovaza and its generics are not approved for CV risk reduction.<sup>2,9-13</sup> In contrast, VASCEPA is proven and FDA approved to reduce CV risk in adults on maximally tolerated statins with TG ≥150 mg/dL and established CVD or diabetes and ≥2 CVD risk factors.<sup>5</sup>",
    //     response: ""
    // },
    {
        text: "Generic DHA-containing omega-3s have shown clinical evidence demonstrating an impact on cardiovascular risk.",
        answer: false,
        description: ["5 trials in the omega-3 class that studied fish oil or mixtures of omega-3 fatty acids that include DHA have failed to demonstrate an impact on the primary cardiovascular endpoint studied.<sup>9-13&dagger;</sup> In the VASCEPA CV Outcomes Trial <span class='nowrap'>(REDUCE-IT<sup>&reg;</sup>)</span>, VASCEPA demonstrated a 25% RRR in CV events<sup>&ddagger;</sup> for adults on maximally tolerated statins with TG ≥150 mg/dL and established CVD or diabetes and ≥2 CVD risk factors.<sup>4</sup>"],
        response: " "
    },
]

export default quizData
