import React, { Component } from "react"
import Header from "../../components/header/Header"
import Hero from '../../components/hero/Hero'
import Quiz from '../../components/quiz/quiz.js'

import ISI from '../../components/isi/ISI'
import Footer from '../../components/footer/Footer'
import Modal from '../../components/modal/Modal'
import HomeModal from '../../components/modal/HomeModal'

import { Waypoint } from 'react-waypoint';
import SEO from "../../components/seo"

import Loader from "../../components/loader"

import "./pharmacist.css"


export default class Pharmacist extends Component {
    state = {
       stickyNav: false,
       isMobile: false,
       modal:  '',
       hideNav: false
    }

  componentDidMount() {
    let bool = window.matchMedia("only screen and (max-width: 1023px)").matches;
    this.setState({ isMobile: bool })
  }


  goModal = (e) => {
    
    this.setState({
     modal: "leavingsite-modal",
     href: "https://amarincorp.com/docs/VASCEPA_Savings_Card.pdf"
   });
  }


  modalClosed = () => {
    this.setState(() => ({ modal: null }))
  }

  handleWaypointEnter = () => {
    this.setState(() => ({ hideNav: false }))
  }

  handleWaypointLeave = () => {
    this.setState(() => ({ hideNav: true }))
  }

  handleFooterEnter = () => {
     // this.setState(() => ({ hideNav: false }))
  }

  handleFooterLeave = () => {
    // this.setState(() => ({ hideNav: true }))
  }


  render() {

    return (
      <>
      <Loader>
      <SEO
        title=""
        keywords={[""]}
        description=""/>
      <HomeModal/>
      <Modal modal={this.state.modal} href={this.state.href} modalClosed={this.modalClosed}/>
        <Header isMobile={this.state.isMobile} hideNav={this.state.hideNav} goModal={this.goModal}/>
        <div className="grad"></div>
        <Hero className="quiz-hero">
          
          <div className="header center-grid mobile-p-x-0 p-x-32">
            <div className="purple-quiz-preheader">The Omega-3 Quiz</div>
    		    <h1 className="headline">Ready to test your omega-3 knowledge?</h1>
          </div>
	      </Hero>
        <div className="wrapper">
          <Waypoint
            onEnter={this.handleWaypointEnter}
            onLeave={this.handleWaypointLeave}/>
          <div className="grid">     
          </div>
          <main className="center-grid m-b-20">
            <section className="endpoints-patient">
                <Quiz/> 
              <div>
              <article class="grad-border">
                <p class="callout-copy">Dispense VASCEPA: Proven treatment with cost savings for most patients</p>
              </article>
                
                <div className="quiz-body-copy">
                No large, well-controlled, head-to-head clinical trials have been conducted between VASCEPA and omega-3 mixtures.<br/>
                Cross-trial comparisons are subject to differences in populations, primary outcomes, and other trial design aspects.<br/>
                DHA-containing products are not FDA approved for co-administration with statins to affect lipid, lipoprotein, or inflammation parameters with the aim of reducing CV mortality or morbidity.

                </div>
                <div class="quiz cta-container m-center fit-content mobile-m-t-20">
                  <h3 class="cta-copy">
                    Get the savings card
                  </h3>
                  <a target="_blank" 
                  href="https://amarincorp.com/docs/VASCEPA_Savings_Card.pdf" 
                  class="cta-btn">
                    <span class="nowrap">View now</span>
                  </a>
                </div>
              </div>  
              </section>
          </main>
        </div>
        <ISI scrollPos={this.state.unstick} disclamer="Visual representations are for illustrative purposes only. The capsule shown is not an identical representation of the product."/>
        <div className="ref-outer">
        
       <div className="footnotes-container">
         <div className='quiz-ref references'>
             <p className="cv-footnote not-submit">CV=cardiovascular; FDA=Food and Drug Administration; LDL-C=low-density lipoprotein cholesterol.</p>
             <p className="cv-footnote submit">CV=cardiovascular; CVD=cardiovascular disease; EPA=eicosapentaenoic acid; FDA=Food and Drug Administration; LDL-C=low-density lipoprotein cholesterol; TG=triglyceride.</p>
             <p style={{textIndent:"-5px"}} className="cv-footnote m-0">*Data on file.</p>
             <p style={{textIndent:"-5px"}} className="cv-footnote m-0"><sup>&dagger;</sup>The 5 trials were: ORIGIN, Risk and Prevention Study, OMEGA, ASCEND, and VITAL.</p>
             <p style={{textIndent:"-5px"}} className="cv-footnote m-0"><sup>&Dagger;</sup>Cardiovascular events including myocardial infarction, stroke, coronary revascularization, and unstable angina requiring hospitalization.</p>
             <p className="cv-footnote"><strong>References: 1. </strong>Mason RP, Sherratt SC. Omega-3 fatty acid fish oil dietary supplements contain saturated fats and oxidized lipids that may interfere with their intended biological benefits. <em>Biochem Biophys Res Commun</em>. 2017;483(1):425-429.
             <strong> 2. </strong>Jacobson TA, Glickstein SB, Rowe JD, Soni PN. Effects of eicosapentaenoic acid and docosahexaenoic acid on low-density lipoprotein cholesterol and other lipids: a review. <em>J Clin Lipidol</em>. 2012;6(1):5-18.
             <strong> 3. </strong>Wei MY, Jacobson TA. Effects of eicosapentaenoic acid versus docosahexaenoic acid on serum lipids: a systematic review and meta-analysis. <em>Curr Atheroscler Rep</em>. 2011;13(6):474-483.
             <strong> 4. </strong>VASCEPA [package insert]. Bridgewater, NJ: Amarin Pharma, Inc.; 2021.
             <strong> 5. </strong>US Food and Drug Administration. Food facts: Dietary supplements. https://www.fda.gov/media/79995/download. Accessed November 19, 2020.
             <strong> 6. </strong>Hilleman D, Smer A. Prescription omega-3 fatty acid products and dietary supplements are not interchangeable. <em>Manag Care</em>. 2016;25(1):46-52.
             <strong> 7. </strong>Kleiner AC, Cladis DP, Santerre CR. A comparison of actual versus stated label amounts of EPA and DHA in commercial omega-3 dietary supplements in the United States. <em>J Sci Food Agric</em>. 2015;95(6):1260-1267.
             <strong> 8. </strong>Lovaza [package insert]. Research Triangle Park, NC: GlaxoSmithKline; 2020.
             <strong> 9. </strong>ORIGIN Trial Investigators; Bosch J, Gerstein HC, Dagenais GR, et al. n–3 fatty acids and cardiovascular outcomes in patients with dysglycemia. <em>N Engl J Med</em>. 2012;367(4):309-318.
             <strong> 10. </strong>Risk and Prevention Study Collaborative Group. n–3 fatty acids in patients with multiple cardiovascular risk factors. <em>N Engl J Med</em>. 2013;368(19):1800-1808.
             <strong> 11. </strong>Rauch B, Schiele R, Schneider S, et al; for the OMEGA Study Group. OMEGA, a randomized, placebo-controlled trial to test the effect of highly purified omega-3 fatty acids on top of modern guideline-adjusted therapy after myocardial infarction. <em>Circulation</em>. 2010;122(21):2152-2159.
             <strong> 12. </strong>ASCEND Study Collaborative Group; Bowman L, Mafham M, Wallendszus K, et al. Effects of n−3 Fatty Acid Supplements in Diabetes Mellitus. <em>N Engl J Med</em>. 2018;379(16):1540-1550.
             <strong> 13. </strong>Manson JE, Cook NR, Lee IM, et al. Marine n−3 Fatty Acids and Prevention of Cardiovascular Disease and Cancer. <em>N Engl J Med</em>. 2019;380(1):23-32.
             </p>
         </div>
       </div>
      </div>
        <Waypoint
         onEnter={this.handleFooterEnter}
         onLeave={this.handleFooterLeave}/>
        <Footer />
        </Loader>
      </>
    )
  }
}
